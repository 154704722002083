import {LockOutlined, UserOutlined,} from '@ant-design/icons';
import {message, Space, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import set from 'lodash/set';
import get from 'lodash/get';
import ProForm, {ProFormCheckbox, ProFormText} from '@ant-design/pro-form';
import {connect, FormattedMessage as F, getLocale, history, NavLink, setLocale, useIntl, useModel} from 'umi';

import storage from 'conversional-persistent-storage';
import {loginAccessTokenRequest, loginRequest} from '@/services/api/user';
import styles from './index.less';
import layoutStyles from '../userLayout.less';
import UserLayout from "@/pages/user/UserLayout";
import {DispatchFunction} from "@/typings/models/ReduxState";
import {authCheck, setAuthenticationTokens} from "@/services/auth";
import {API} from "@/services/api/typings";
import dev from '@/constants/environment/dev';
import { convertLanguageToUmiLocale, getLanguageFromUmiLocale } from '@/utils/locale';

const TOKEN_AUTHENTICATION = true
const DEFAULT_LANGUAGE = 'de'
/** This method will jump to redirect - The location of the parameter */
const goto = () => {
  if (!history) return;
  setTimeout(() => {
    const {query} = history.location;
    const {redirect} = query as { redirect: string };
    history.push(redirect || '/check');
  }, 10);
};

type LoginProps = {
  dispatch: DispatchFunction,
  onChange?: (fields: any) => void
  initialValues?: {
    [key: string]: string
  }
}

const Login: React.FC<LoginProps> = ({dispatch, onChange, initialValues = {}}: LoginProps) => {
  const [submitting, setSubmitting] = useState(false);
  const {initialState, setInitialState} = useModel('@@initialState');

  const intl = useIntl();
  const defaultloginFailureMessage = intl.formatMessage({
    id: 'pages.login.failure',
    defaultMessage: 'Login failed, please try again！',
  });
  const defaultloginSuccessMessage = intl.formatMessage({
    id: 'pages.login.success',
    defaultMessage: 'login successful！',
  });
  useEffect(() => {
    dispatch({
      type: 'journey/reset'
    })
  }, [])



  /**
   * Log in with the cookie method (Server sends set-cookie header and authentication is done automatically by the browser)
   * @param values: {email: string, password: string}
   * */
  const cookieMethodLogin = async (values: API.LoginParams) => {
    setSubmitting(true);

    try {
      // login
      const APICall = await loginRequest({...values});
      if (APICall.response.status === 200) {
        handleSuccessfulLogin(APICall.data)
      }else{
        message.error(APICall.data.message || defaultloginFailureMessage);
      }
    } catch (error) {
      message.error(defaultloginFailureMessage);
    }
    setSubmitting(false);
  }

  /**
   * Log in with the token method (Getting access token & refresh token in response)
   * @param values: {email: string, password: string}
   * */
  const tokenMethodLogin = async (values: API.LoginParams) => {
    setSubmitting(true);
    try {
      // login
      const APICall = await loginAccessTokenRequest({...values});
      if (APICall.response.status === 200) {
        setAuthenticationTokens(APICall.data)
        try{
          const Auth = await authCheck()
          handleSuccessfulLogin(Auth)
        }catch(error){
          message.error(error.message);
        }
      }else{
        setSubmitting(false);
        message.error(APICall.data.message || defaultloginFailureMessage);
      }
    } catch (error) {
      setSubmitting(false);
      message.error(defaultloginFailureMessage);
    }
  }
const addRolesToUserInDevMode =(user)=>{
  if (storage.getItem('rootApiUrl',undefined,"localStorage") === dev.ROOT_URL) {
    const devRole = storage.getItem('devModeRole', undefined, 'localStorage');
    if (devRole && devRole !== 'null') set(user, 'User.role', JSON.parse(devRole));
  }
}
  const setUserLanguage = (user) => {
    const locale = convertLanguageToUmiLocale(get(user, 'Settings.locale') || DEFAULT_LANGUAGE);
    const clientStoredLocale = getLocale();
    if (locale && locale !== clientStoredLocale) {
      setLocale(locale,false);
    } else if (!locale && clientStoredLocale) {
      dispatch({
        type: 'user/changeSettings',
        payload: {
          locale: getLanguageFromUmiLocale(clientStoredLocale),
        },
      });
    }
  };
  /**
   * Sets the user in redux & initial state
  * @param user: Logged in user
  * */
  const handleSuccessfulLogin = (user: unknown) => {
    addRolesToUserInDevMode(user)
    dispatch({
      type: "user/loggedIn",
      payload: user
    })
    // Omit InitialState
    setInitialState({
      ...initialState,
      currentUser: user
    })

    setUserLanguage(user);
    message.success(defaultloginSuccessMessage);
    goto();
    return;
  }

  const handleSubmit = async (values: API.LoginParams) => {
    const loginFunction = TOKEN_AUTHENTICATION ? tokenMethodLogin : cookieMethodLogin
    await loginFunction(values)
  };

  return (
    <UserLayout>
      <div>
        <Typography.Title className={layoutStyles.pageTitle}><F id={"pages.login.loginTitle"}
                                                                defaultMessage={"Welcome! Sign In"}/></Typography.Title>
        <ProForm
          initialValues={{
            autoLogin: true,
            ...initialValues
          }}
          id={"auth-form"}
          isKeyPressSubmit
          onValuesChange={onChange && onChange}
          submitter={{
            searchConfig: {
              submitText: intl.formatMessage({
                id: 'pages.login.submit',
                defaultMessage: 'log in',
              }),
            },
            render: (_, dom) => dom.pop(),
            submitButtonProps: {
              loading: submitting,
              size: 'large',
              style: {
                width: '100%',
              },
            },
          }}
          onFinish={async (values) => {
            handleSubmit(values as API.LoginParams);
          }}
        >
          <>
            <ProFormText
              name="email"
              fieldProps={{
                size: 'large',
                prefix: <UserOutlined className={styles.prefixIcon}/>,
              }}
              placeholder={intl.formatMessage({
                id: 'pages.login.email.placeholder',
                defaultMessage: 'Email',
              })}
              rules={[
                {
                  required: true,
                  message: (
                    <F
                      id="pages.login.email.required"
                      defaultMessage="Please Enter Email!"
                    />
                  ),
                },
              ]}
            />
            <ProFormText.Password
              name="password"
              fieldProps={{
                size: 'large',
                prefix: <LockOutlined className={styles.prefixIcon}/>,
              }}
              placeholder={intl.formatMessage({
                id: 'pages.login.password.placeholder',
                defaultMessage: 'Password',
              })}
              rules={[
                {
                  required: true,
                  message: (
                    <F
                      id="pages.login.password.required"
                      defaultMessage="Please enter the password！"
                    />
                  ),
                },
              ]}
            />
          </>
          <div
            style={{
              marginBottom: 24,
            }}
          >
            <ProFormCheckbox noStyle name="autoLogin">
              <F id="pages.login.rememberMe" defaultMessage="automatic log-in"/>
            </ProFormCheckbox>
            <NavLink
              to={"/user/forgotpassword"}
              style={{
                float: 'right',
              }}
              className={"color-secondary"}
            >
              <F id="pages.login.forgotPassword" defaultMessage="Forgot Password"/>
            </NavLink>
          </div>
        </ProForm>
        <Space style={{marginTop: 24, width: "100%", justifyContent: "center"}}>
          <F id="pages.login.dontHaveAccount" defaultMessage="Don't have an Account?"/>
          <a href="https://www.conversion.al/de/get-started" target="_blank" className={"color-secondary"}>
            <F id="pages.login.requestDemo" defaultMessage="Request Demo Now."/>
          </a>
        </Space>
      </div>
    </UserLayout>
  );
};

export default connect()(Login);
